import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "menu-panel" }
const _hoisted_2 = { class: "popup-main" }
const _hoisted_3 = { class: "popup-header" }
const _hoisted_4 = { class: "popup-content" }
const _hoisted_5 = {
  key: 0,
  class: "game-menu-list"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "menu-name" }
const _hoisted_8 = { class: "menu-list" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "menu-name" }
const _hoisted_11 = {
  key: 0,
  class: "badge-wrap"
}
const _hoisted_12 = {
  key: 1,
  class: "badge-wrap"
}
const _hoisted_13 = {
  key: 2,
  class: "badge-wrap"
}
const _hoisted_14 = { class: "popup-footer" }
const _hoisted_15 = { class: "follow-wrap" }
const _hoisted_16 = { class: "flex justify-center" }
const _hoisted_17 = { class: "follow-label" }

import { ref, reactive, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia';
import { useHelpTool, usePWA } from '@/hooks';
import { useUserStore, useAccountStore, useGameStore, usePopupStore, useReddotStore } from "@/store";
import ThemeSwitch from '@/components/ThemeSwitch';


export default {
  __name: 'MenuPanel',
  props: ['show'],
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const router = useRouter()
const { doFollow } = useHelpTool()
const { installPWA } = usePWA();

const userStore = useUserStore();
const { uid } = storeToRefs(userStore);
const accountStore = useAccountStore();
const { point } = storeToRefs(accountStore);
const gameStore = useGameStore();
const { gameMenu, activeGameMenu } = storeToRefs(gameStore);
const popupStore = usePopupStore();
const redDotStore = useReddotStore();
const { loginBonusRewardNum, inviteFriendToEarnNum, inboxUnread, taskRewardNum } = storeToRefs(redDotStore);

const menuList = reactive([
  { code: 'records',  pathName: 'records' },
  { code: 'chat',  pathName: 'livechat' },
  // { code: 'events',  pathName: 'event' },
  { code: 'security',  pathName: 'securityCenter' },
  { code: 'settings',  pathName: 'setting' },
  { code: 'redeem',  pathName: 'redeem' },
]);

const socialMediaList = ref(['facebook', 'telegram']);

const clickGameMenu = item => {
  if (router.currentRoute.value.name === 'game') {
    gameStore.changeMenu(item);
    emit('onClose');
  } else {
    router.push({ name: 'game' })
  }
} 

const clickMenu = item => {
  if(item.onClick) {
    item.onClick()
  } else {
    router.push({ name: item.pathName })
  }
}

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: __props.show,
      position: "left",
      "close-on-click-overlay": false,
      onClickOverlay: _cache[1] || (_cache[1] = $event => (emit('onClose')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_component_van_image, {
              class: "popup-title",
              src: require('@/assets/img/common/logo-title.png'),
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.$router.push({ name: 'game' })))
            }, null, 8, ["src"]), [
              [_directive_audio]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_unref(gameMenu) && _unref(gameMenu).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(gameMenu), (item) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(`menu-item ${ _unref(router).currentRoute.value.name === 'game' && _unref(activeGameMenu) && _unref(activeGameMenu).code === item.code ? 'active' : 'inactive'}`),
                      key: item.code,
                      onClick: $event => (clickGameMenu(item))
                    }, [
                      _createVNode(_component_van_image, {
                        class: "menu-icon",
                        fit: "contain",
                        src: require(`@/assets/img/common/menu/${item.code}.png`)
                      }, null, 8, ["src"]),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(item.name) + " GAMES", 1),
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "divider-line" }, null, -1))
                    ], 10, _hoisted_6)), [
                      [_directive_audio]
                    ])
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList, (item) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(`menu-item ${_ctx.$route.name === item.pathName ? 'active' : 'inactive'}`),
                  key: item.code,
                  onClick: $event => (clickMenu(item))
                }, [
                  _createVNode(_component_van_image, {
                    class: "menu-icon",
                    fit: "contain",
                    src: require(`@/assets/img/common/menu/${item.code}.png`)
                  }, null, 8, ["src"]),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(`menu.${item.code}`).toUpperCase()), 1),
                  (item.code === 'inbox' && _unref(inboxUnread))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(inboxUnread)), 1))
                    : (item.code === 'signUpBonus' && _unref(loginBonusRewardNum))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(loginBonusRewardNum)), 1))
                      : (item.code === 'task' && _unref(taskRewardNum))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(taskRewardNum)), 1))
                        : _createCommentVNode("", true),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider-line" }, null, -1))
                ], 10, _hoisted_9)), [
                  [_directive_audio]
                ])
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createVNode(_component_van_image, {
              class: "app-logo",
              src: require('@/assets/img/common/logo-title.png'),
              onClick: _unref(installPWA)
            }, null, 8, ["src", "onClick"]), [
              [_directive_audio]
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(socialMediaList.value, (item) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_van_image, {
                    class: "social-media-icon",
                    fit: "contain",
                    src: require(`@/assets/img/common/socialMedia/icon/${item}.png`),
                    onClick: $event => (_unref(doFollow)({ type: item }))
                  }, null, 8, ["src", "onClick"])), [
                    [_directive_audio]
                  ])
                }), 256))
              ]),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('socialMedia.followShare')), 1)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

}